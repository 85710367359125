.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-red{
  background-color: #bc3a3a;
}
.blue{
  color: #5c5ccd;
}
.turquoise{
  color: rgb(92,205,205);
}
/*
below are colors that are suggested by ai they are a grouping
*/
.bg-yellow{
  background-color: #F4E173;
}
.bg-whiteish{
  background-color: #EAF1E7;
}.bg-blue{
  background-color: #11538C;
}.bg-darkblue{
  background-color: #121E4A;
}
.bg-darkerblue{
  background-color: #171427;
}
